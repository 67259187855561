REGISTRE.delibs = {
  caldDelibId: function (donnees, list) {
    var index, lastItem = list[list.length - 1]
    if (donnees.DELIB_ID == '') {
      if (lastItem.DELIB_DATE === donnees.DELIB_DATE) {
        index = parseInt(lastItem.DELIB_ID.substring(15,17)) + 1
      } else {
        index = 1
      }
      return "DL-" + (donnees.DELIB_TYPE == 'bureau' ? 'BU-' : 'CO-') + donnees.DELIB_DATE.replace(/-/g, "") + "-" + (index < 10 ? '0' : '') + index
    } else {
      return donnees.DELIB_ID
    }
  },
  calcMatiereNom: function (donnees) {
    switch (donnees.DELIB_MATIERE_CODE) {
      case "1.1": return "1.1  Marchés publics"; break;
      case "1.2": return "1.2  Délégation de service public"; break;
      case "1.3": return "1.3  Conventions de Mandat"; break;
      case "1.4": return "1.4  Autres types de contrats"; break;
      case "1.5": return "1.5  Transactions /protocole d accord transactionnel"; break;
      case "1.6": return "1.6  Actes relatifs à la maîtrise d'oeuvre"; break;
      case "1.7": return "1.7  Actes speciaux et divers"; break;
      case "2.1": return "2.1  Documents d urbanisme "; break;
      case "2.2": return "2.2  Actes relatifs au droit d'occupation ou d utilisation des sols"; break;
      case "2.3.1": return "2.3.1  Institution et exercice du droit de préemption urbain"; break;
      case "2.3.2": return "2.3.2  Non exercice du droit de préemption"; break;
      case "3.1.1": return "3.1.1  supérieures à 75 000 euros"; break;
      case "3.1.2": return "3.1.2  inférieures à 75 000 euros"; break;
      case "3.5.1": return "3.5.1  Occupation temporaire, permission de voirie, alignement"; break;
      case "3.5.2": return "3.5.2  Autres actes"; break;
      case "4.1.1": return "4.1.1  Délibérations et conventions"; break;
      case "4.1.2": return "4.1.2  Arrêtés"; break;
      case "4.2.1": return "4.2.1  Délibérations et conventions"; break;
      case "4.2.2": return "4.2.2  Arrêtés et contrats"; break;
      case "5.1": return "5.1  Election executif"; break;
      case "5.2": return "5.2  Fonctionnement des assemblees"; break;
      case "5.3": return "5.3  Designation de representants"; break;
      case "5.4": return "5.4  Delegation de fonctions"; break;
      case "5.5": return "5.5  Delegation de signature"; break;
      case "5.6": return "5.6  Exercice des mandats locaux"; break;
      case "5.7": return "5.7  Intercommunalite"; break;
      case "5.8": return "5.8  Decision d ester en justice"; break;
      case "6.1": return "6.1  Police municipale"; break;
      case "6.2": return "6.2  Pouvoir du president du conseil general"; break;
      case "6.3": return "6.3  Pouvoir du president du conseil regional"; break;
      case "6.4": return "6.4  Autres actes reglementaires"; break;
      case "6.5": return "6.5  Actes pris au nom de l Etat et soumis au controle hierarchique"; break;
      case "7.1": return "7.1  Decisions budgetaires"; break;
      case "7.2.1": return "7.2.1  Vote des taux d'imposition"; break;
      case "7.2.2": return "7.2.2  Autres taxes et redevances"; break;
      case "7.3.1": return "7.3.1  Emprunts"; break;
      case "7.3.2": return "7.3.2  Lignes de trésorerie"; break;
      case "7.3.3": return "7.3.3  Garanties d'emprunts"; break;
      case "7.5.1": return "7.5.1  Subventions supérieures à 23 000 euros"; break;
      case "7.5.2": return "7.5.2  Subventions inférieures à 23 000 euros"; break;
      case "7.6.1": return "7.6.1  Contributions reçues"; break;
      case "7.6.2": return "7.6.2 Contributions versées"; break;
      case "8.1": return "8.1  Enseignement"; break;
      case "8.2": return "8.2  Aide sociale"; break;
      case "8.3": return "8.3  Voirie"; break;
      case "8.4": return "8.4  Amenagement du territoire"; break;
      case "8.5": return "8.5  Politique de la ville-habitat-logement"; break;
      case "8.6": return "8.6  Emploi-formation professionnelle"; break;
      case "8.7": return "8.7  Transports"; break;
      case "8.8": return "8.8  Environnement"; break;
      case "8.9": return "8.9  Culture"; break;
      case "9.1": return "9.1  Autres domaines de competences des communes"; break;
      case "9.2": return "9.2  Autres domaines de competences des departements"; break;
      case "9.3": return "9.3  Autres domaines de competences des regions"; break;
      case "9.4": return "9.4  Voeux et motions"; break;
    }
    return ""
  },
  calcDelibAnnee: function (donnees) {
    return donnees.DELIB_DATE.substring(0,4)
  }
}